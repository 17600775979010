import React from "react";
import Typography from "@mui/material/Typography";

const ColumnTitle = ({ title }) => (
  <Typography
    variant="caption"
    sx={{
      fontWeight: 700,
      textTransform: "uppercase",
      marginBottom: 1,
      display: "block",
    }}
  >
    {title}
  </Typography>
);

export default ColumnTitle;
