import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

const ButtonWithMargin = ({ href, label }) => {
  const theme = useTheme();

  return (
    <Box marginBottom={1 / 2}>
      <Button
        href={href}
        component={"a"}
        target="_blank"
        sx={{
          justifyContent: "flex-start",
          color: theme.palette.text.primary,
        }}
        fullWidth
      >
        {label}
      </Button>
    </Box>
  );
};

export default ButtonWithMargin;
