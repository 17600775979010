import React from "react";
import NextLink from "next/link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { useRouter } from "next/dist/client/router";
import { useTranslation } from "next-i18next";

import fluicityLogoSvg from "../../../public/static/icons/fluicity.svg";
import ButtonWithMargin from "./ButtonWithMargin";
import ColumnTitle from "./ColumnTitle";
import Image from "next/image";

const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const { locales, pathname, asPath } = useRouter();

  const pages = [
    {
      title: t("footer.solutions"),
      id: "solutions",
      pages: [
        {
          title: t("footer.features"),
          href: "/plateforme-consultation-citoyenne",
        },
        {
          title: t("footer.call_for_proposals"),
          href: "/appel-a-propositions",
        },
        {
          title: t("footer.participatory_budget"),
          href: "/budget-participatif",
        },
        {
          title: t("footer.offers"),
          href: "/pricing",
        },
        {
          title: t("footer.leaflet"),
          href: "/static/resources/brochure_web_fluicity.pdf",
          target: "_blank",
          dontPassHref: true,
        },
      ],
    },
    {
      title: t("footer.clients"),
      id: "clients",
      pages: [
        {
          title: t("footer.public_sector"),
          href: "/publics",
        },
        {
          title: t("footer.private_sector"),
          href: "/enterprises",
        },
        {
          title: t("footer.partnerships"),
          href: "/partnerships",
        },
      ],
    },
    {
      title: t("footer.about"),
      id: "about",
      pages: [
        {
          title: t("footer.about_us"),
          href: "/about",
        },
        {
          title: t("footer.our_expertise"),
          href: "/our-expertise",
        },
        {
          title: t("footer.jobs"),
          href: "https://www.linkedin.com/company/fluicity",
        },
        {
          title: t("footer.privacy"),
          href: t("footer.privacy_link"),
        },
      ],
    },
    {
      title: t("footer.resources"),
      id: "resources",
      pages: [
        {
          title: t("footer.blog"),
          href: "/blog",
        },
        {
          title: t("footer.resources"),
          href: "/resources",
        },
      ],
    },
  ];

  return (
    <Box marginY={2}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box
              display="flex"
              component="a"
              underline="none"
              href="/"
              title="Fluicity"
              width={120}
              height={40}
            >
              <Image
                src={fluicityLogoSvg}
                alt="Fluicity logo"
                width={120}
                height={40}
              />
            </Box>
            <Box display="flex" flexWrap="wrap" alignItems="center">
              {locales.map((locale) => (
                <Box margin={1} key={locale}>
                  <NextLink href={asPath} locale={locale} passHref>
                    <Button
                      href={asPath}
                      component={"a"}
                      sx={{
                        justifyContent: "flex-start",
                        color: theme.palette.text.primary,
                      }}
                      startIcon={locale == "en" ? "🇬🇧" : "🇫🇷"}
                    >
                      {locale == "en" ? "English" : "Français"}
                    </Button>
                  </NextLink>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>

        {pages.map((item, i) => (
          <Grid item xs={12} sm={6} md={2} key={i}>
            <Box marginBottom={3}>
              <ColumnTitle title={item.title} />

              <Box>
                {item.pages.map((page, i) => (
                  <Box marginBottom={1 / 2} key={i}>
                    <NextLink href={page.href} passHref={!page.dontPassHref}>
                      <Button
                        href={page.href} // Used for non internal links
                        component={"a"}
                        target={page.target}
                        sx={{
                          justifyContent: "flex-start",
                          color: theme.palette.text.primary,
                          backgroundColor:
                            pathname === page.href
                              ? theme.palette.alternate.main
                              : "transparent",
                        }}
                        fullWidth
                      >
                        {page.title}
                      </Button>
                    </NextLink>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        ))}

        <Grid item xs={12} sm={6} md={3}>
          <Box marginBottom={3}>
            <ColumnTitle title={t("footer.contact")} />
            <ButtonWithMargin
              href="mailto:info@flui.city"
              label="info@flui.city"
            />
            <ButtonWithMargin
              href="tel:+33 1 76 50 60 90"
              label={`${t("footer.france")} +33 1 76 50 60 90`}
            />
            <ButtonWithMargin
              href="tel:+32 2 335 84 61"
              label={`${t("footer.belgium")} +32 2 335 84 61`}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
